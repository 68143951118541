<template>
  <v-card rounded="lg" min-height="70vh">
    <v-card-text cols="6" v-if="!loaded">
      <v-skeleton-loader type="list-item-avatar-three-line@13"/>
    </v-card-text>
    <v-card-text class="py-0">
      <v-timeline dense>
        <v-timeline-item small v-for="(experience) in experiences" :key="experience._id" color="light-blue lighten-2">
          <v-card class="elevation-1">
            <v-card-title class="headline">
              <span>{{ experience.title[language] }}</span>
              <v-spacer></v-spacer>
              <span class="headline font-weight-light light-blue--text lighten-2" v-text="experience.period"/>
            </v-card-title>
            <v-card-subtitle class="d-flex">
              <span v-text="experience.company + ' - ' + experience.place" class="text-left"/>
              <v-spacer></v-spacer>
              <span v-text="experience.time" class="font-weight-light light-blue--text lighten-2 text-right"/>
            </v-card-subtitle>
            <v-divider class="mx-4"></v-divider>
            <v-card-text>
              <p v-if="experience.assignments[language]">{{ experience.assignments[language] }}</p>
              <p v-if="experience.customers.length"><strong>{{ $t('label.customers') }}:</strong> {{ experience.customers.join(', ') }}</p>
              <p v-if="experience.projects.length"><strong>{{ $t('label.projects') }}:</strong> {{ experience.projects.join(', ') }}</p>
              <v-chip-group column>
                <v-chip v-for="(skill) in experience.skills" :key="skill._id" small>
                  <v-icon left small>
                    {{ getIconName(skill) }}
                  </v-icon>
                  {{ skill }}
                </v-chip>
              </v-chip-group>
            </v-card-text>
          </v-card>
        </v-timeline-item>

        <v-timeline-item class="mt-6" color="grey" icon="mdi-school">
          <span class="headline">{{ $t('label.graduation') }}</span>
        </v-timeline-item>

        <v-timeline-item small v-for="(graduation) in graduations" :key="graduation._id" color="light-blue lighten-2">
          <v-card class="elevation-1">
            <v-card-title class="headline">
              <span>{{ graduation.course[language] }}</span>
              <v-spacer></v-spacer>
              <span class="headline font-weight-light light-blue--text lighten-2" v-text="graduation.period"/>
            </v-card-title>
            <v-card-subtitle v-text="graduation.institution[language]"/>
            <v-card-text v-if="graduation.description[language]" v-text="graduation.description[language]"/>
          </v-card>
        </v-timeline-item>

        <v-timeline-item class="mt-6" color="grey" icon="mdi-police-badge">
          <span class="headline">{{ $t('label.certifications') }}</span>
        </v-timeline-item>

        <v-timeline-item small v-for="(certification) in certifications" :key="certification._id" color="light-blue lighten-2">
          <v-card class="elevation-1">
            <v-row>
              <v-col xs="12" sm="12" md="4" lg="2" :class="{
                'align-end': !isMobile,
                'text-right': !isMobile,
                'text-center': isMobile,
                'pr-0': !isMobile
              }">
                <v-avatar class="ma-3" size="125" tile>
                  <v-img :src="certification.badge"/>
                </v-avatar>
              </v-col>
              <v-col xs="12" sm="12" md="8" lg="10" :class="{
                'pr-0': !isMobile
              }">
                <div class="ma-0 vw-100">
                  <v-card-title class="headline">
                    <span>{{ certification.name }}</span>
                    <v-spacer></v-spacer>
                    <span class="headline font-weight-light light-blue--text lighten-2" v-text="(new Date(certification.issue_date)).toLocaleDateString('en-CA')"/>
                  </v-card-title>
                  <v-card-subtitle v-text="certification.organization"/>
                  <v-card-text v-if="certification.credential">
                    {{ certification.credential }}
                    <v-btn text color="primary" target="_blank" :href="certification.url" x-small fab>
                      <v-icon>mdi-open-in-new</v-icon>
                    </v-btn>
                  </v-card-text>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-timeline-item>

      </v-timeline>
    </v-card-text>

  </v-card>
</template>
<script>
export default {
  data: () => ({}),
  computed: {
    experiences(){
      return this.$store.getters['experiences/experiences'] || {}
    },
    graduations(){
      return this.$store.getters['graduations/graduations'] || {}
    },
    certifications(){
      return this.$store.getters['certifications/certifications'] || {}
    },
    language(){
      return this.$i18n.locale
    },
    loaded() {
      return this.experiences.length && this.graduations.length
    },
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
        case 'sm': return true
        case 'md':
        case 'lg':
        case 'xl': return false
      }
      return null;
    }
  },
  mounted() {
    this.loadExperiences();
    this.loadGraduations();
    this.loadCertifications();
  },
  methods: {
    /**
     * Load the experiences
     * @returns {Promise<boolean>}
     */
    loadExperiences() {
      return this.$store.dispatch('experiences/load');
    },

    /**
     * Load the graduations
     * @returns {Promise<any>}
     */
    loadGraduations() {
      return this.$store.dispatch('graduations/load');
    },

    /**
     * Load the certifications
     * @returns {Promise<any>}
     */
    loadCertifications() {
      return this.$store.dispatch('certifications/load');
    },

    /**
     * Convert number year in rgb color
     * @param year
     * @returns {string}
     */
    convertYearToColor(year) {
      let max = 255,
          firstYear = 2004,
          pace = max / ((new Date()).getFullYear() - firstYear),
          diffYear = parseInt(year.substring(0, 4)) - firstYear,
          colorTon = parseInt(pace) * diffYear;
      return `rgba(${max - colorTon},${colorTon},${colorTon})`;
    },

    /**
     * Create name icon base on name skill
     * @param skill
     * @returns {string}
     */
    getIconName(skill)
    {
      let iconName = skill.toLowerCase().replace('.', '');
      return `mdi-${iconName} mdi-language-${iconName}`;
    }
  }
}
</script>